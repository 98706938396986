@charset "utf-8";

/*
Theme Name: tec-info
Theme URI:
Description: HOC tecのinformation等のページです。
Version: 1.1
Author: owndmedia-lab
*/

@use 'css/variables';

/*--------------------------------------------------------------
## 共通
--------------------------------------------------------------*/

body {
  width: 100%;
  font-size: 16px;
  color: #222;
  font-family: 'Noto Serif JP', serif;
  font-feature-settings: "palt";
  letter-spacing: 2px;
  margin: 0 auto;
  background-color: rgba(240, 240, 240, 0.9);
  overflow-x: hidden;
}

main {
  overflow-x: hidden;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.8rem;
}

p {
  font-size: 1rem;
  line-height: 2rem;
  margin: 1rem 0;
}

a {
  text-decoration: none;
  color: variables.$color1;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

.container {
  margin: auto;
}

.container01 {
  width: calc(100% - 5vw);
}

.container02 {
  width: 1000px;
}

.container03 {
  width: 920px;
}

.container04 {
  width: min(90%, 1280px);
}

.container05 {
  width: min(100%, 1440px);
}

/* コンポーネント */

.c-ttl01 {
  margin-bottom: 4rem;
  padding-inline: 1rem;

  &.center {
    text-align: center;
  }

  &--main {
    font-size: clamp(1.7rem, 0.86rem + 2.4vw, 2.3rem);
  }

  &--sub {
    margin-top: 0.5rem;
    color: variables.$color3;
    font-size: clamp(1rem, 0.86rem + 0.4vw, 1.1rem);
  }
}

.c-ttl02 {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  color: variables.$color1;
}

:is(.top, .lower) {

  .c-main-contents01 {
    padding: 3rem 0 5rem;

    .contents_section {
      position: relative;
      margin-bottom: 22rem;

      &-text {
        position: absolute;
        color: #fff;
        text-align: left;
        padding: 5rem;
        width: 35vw;
        min-height: 300px;
        bottom: -8rem;
        z-index: 10;

        h4 {
          font-size: 1.8rem;
          margin-bottom: 2rem;
        }

        p {
          margin-bottom: 2.5rem;
          word-break: auto-phrase;
          text-wrap: balance;
        }

        .btn {
          margin-inline: auto;
        }
      }

      img {
        width: 60vw;
        height: 39vw;
        object-fit: cover;
      }

      &:nth-child(1 of .contents_section) {
        text-align: right;

        .contents_section-text {
          background-color: variables.$color1;
        }

        &::before {
          border-right: 60vw solid transparent;
          border-bottom: 25vw solid rgba(0, 0, 0, 0.2);
          bottom: 0.1vw;
          left: -2.5vw;
        }

        &::after {
          border-left: 40vw solid transparent;
          border-top: 5vw solid rgba(0, 0, 0, 0.3);
          bottom: -5vw;
          right: -2.5vw;
        }
      }

      &:nth-child(2n of .contents_section) {
        text-align: left;

        img {
          z-index: 10;
        }

        .contents_section-text {
          background-color: #333;
          right: 0;
        }

        &::before {
          border-right: 30vw solid transparent;
          border-top: 3vw solid rgba(variables.$decoColor, 0.6);
          bottom: -2.9vw;
          left: -2.5vw;
        }

        &::after {
          border-left: 70vw solid transparent;
          border-bottom: 30vw solid rgba(variables.$decoColor, 0.3);
          bottom: 0.1vw;
          right: -2.5vw;
        }
      }

      &:nth-child(3 of .contents_section) {
        text-align: right;

        .contents_section-text {
          background-color: variables.$color2;
        }

        &::before {
          border-right: 80vw solid transparent;
          border-bottom: 20vw solid rgba(0, 0, 0, 0.3);
          bottom: 0.1vw;
          left: -2.5vw;
        }

        &::after {
          border-left: 20vw solid transparent;
          border-top: 5vw solid rgba(0, 0, 0, 0.2);
          bottom: -4.9vw;
          right: -2.5vw;
        }
      }
    }
  }

  .c-main-contents02 {
    margin-bottom: 10rem;

    .c-main-contents02__list {
      display: grid;
      gap: 12rem;
    }

    .c-main-contents02__item {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: max(5%, 3rem);

      .c-main-contents02__item--bg {
        position: absolute;
        top: 50%;
        z-index: -1;
        width: 80%;
        height: 105%;
        background-color: rgba(variables.$decoColor, 0.05);
      }

      &:nth-child(odd) {

        .c-main-contents02__item--bg {
          right: 0;
          transform: translateY(-50%) skewY(-4deg);
        }
      }

      &:nth-child(even) {
        flex-direction: row-reverse;

        .c-main-contents02__item--bg {
          left: 0;
          transform: translateY(-50%) skewY(4deg);
        }
      }
    }

    .c-main-contents02__box {
      width: 42%;
    }

    .c-main-contents02__head {
      margin-bottom: 1.5rem;
      font-size: clamp(1.5rem, 1.08rem + 1.2vw, 1.8rem);
      color: variables.$color1;
    }

    .c-main-contents02__desc {
      word-break: auto-phrase;
      text-wrap: balance;
    }

    .c-main-contents02__img {
      width: 40%;
      aspect-ratio: 1.618 / 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .c-main-contents03 {
    margin-bottom: 15rem;
    padding-top: 0;

    .c-main-contents03__list {
      display: grid;
      gap: 10rem;
      padding-block: 2rem;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-color: rgba(variables.$decoColor, 0.1);
        transform: skewY(-6deg) translateY(5rem);
        transform-origin: 100%;
      }
    }

    .c-main-contents03__item {
      display: flex;
      flex-wrap: wrap;
      gap: max(5%, 3rem);

      &:nth-child(odd) {
        flex-direction: row-reverse;

        .c-main-contents03__img {
          transform: skewY(-6deg);
          transform-origin: 100%;

          img {
            transform: skewY(6deg);
          }

        }
      }

      &:nth-child(even) {

        .c-main-contents03__img {
          transform: skewY(6deg);
          transform-origin: 0%;

          img {
            transform: skewY(-6deg);
          }

        }
      }
    }

    .c-main-contents03__box {
      position: relative;
      width: 33%;
      margin-top: 15%;
    }

    .c-main-contents03__head {
      margin-bottom: 2rem;
      font-size: 1.8rem;
      color: variables.$color1;
    }

    .c-main-contents03__desc {
      margin-bottom: 3rem;
    }

    .c-main-contents03__img {
      width: 48%;
      aspect-ratio: 1.618 / 1;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        scale: 1.2;
      }
    }
  }

}

/*ローディング画面*/
.loader-wrap {
  position: fixed;
  display: grid;
  place-items: center;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: variables.$color1;
  overflow: hidden;
  z-index: 1000;
}

.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {

  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {

  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}


/*ボタンデザイン*/
.btn1 {
  position: relative;
  font-size: clamp(1.5rem, 0.8rem + 2vw, 2rem);
  color: #000;
  padding: 0.5rem 2rem 0.8rem;
  border: 1px solid #000;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    top: -10px;
    right: -10px;
    transition: .4s;
  }

  &:hover {
    &::before {
      top: 0;
      right: 0;
      transition: .4s;
    }
  }
}

.btn2 {
  position: relative;
  display: block;
  width: fit-content;
  font-size: 1.5rem;
  color: inherit;
  padding: 0.8rem 2rem 1rem;
  border: 1px solid;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid;
    top: -10px;
    right: -10px;
    transition: .4s;
  }

  &:hover {
    &::before {
      top: 0;
      right: 0;
      transition: .4s;
    }
  }
}

.more-btn {
  position: relative;
  display: inline-block;
  padding-right: 2rem;
  padding-bottom: 1rem;

  &::before,
  &::after {
    position: absolute;
    content: '';
  }

  &::before {
    width: 160px;
    height: 2px;
    background-color: #000;
    right: 0;
    bottom: 0;
  }

  &::after {
    width: 15px;
    height: 2px;
    background-color: #000;
    right: 0;
    bottom: 0.25rem;
    transform: rotate(30deg);
  }
}

/* 三角形の装飾 */
.deco {
  position: relative;

  &::before,
  &::after {
    position: absolute;
    content: '';
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}

/* その他共通 */
.flex {
  display: flex;
  flex-wrap: wrap;
}

.contents_table {
  max-width: 960px;
  border: none;

  th,
  td {
    border-bottom: 1px solid #000;
    padding: 1.5rem 2rem;
  }

  th {
    vertical-align: middle;
  }

  td {
    text-align: left;
  }
}

.bk-img {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  min-height: 450px;
  width: 100%;
}

.article-text_categories {
  display: flex;
  gap: 1rem;
}

.category,
.post-categories li a {
  display: inline-block;
  background-color: variables.$color2;
  font-size: .9rem;
  color: #fff;
  padding: 0.3em 1em;
  transition: .4s;
  margin: 0;

  &:hover {
    background-color: #000;
    transition: .4s;
  }
}

/*パンくずリスト*/
.breadcrumb {
  margin-bottom: 5rem;

  li {
    display: inline-block;
    font-size: .8rem;
    position: relative;
    margin-right: 2rem;

    a {
      color: variables.$color2;
      transition: .4s;

      &:hover {
        color: variables.$color1;
        transition: .4s;
      }
    }

    &::before {
      position: absolute;
      content: '';
      width: 4px;
      height: 4px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      transform: rotate(45deg);
      right: -1.2rem;
      bottom: 0.4rem;
    }

    &:last-child::before {
      display: none;
    }
  }
}

/*ページャー*/
.page-numbers {
  display: flex;
  gap: 1rem;
  padding-top: 3rem;

  li {
    padding: 0;

    .current {
      color: #fff;
      background-color: #000;
      padding: .8rem;
    }

    .prev,
    .next {
      background: none;
      color: variables.$color2;
      transition: .4s;

      &:hover {
        background: none;
        color: variables.$color1;
        transition: .4s;
      }
    }

    a {
      display: block;
      color: #fff;
      background-color: variables.$color2;
      padding: .8rem;
      transition: .4s;

      &:hover {
        background-color: variables.$color1;
        transition: .4s;
      }
    }
  }
}

/*スクロールバーデザイン*/
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: variables.$color2;
  border-radius: 10px;
  box-shadow: inset 0 0 0 2px #fff;
}

/*--------------------------------------------------------------
## header.php
--------------------------------------------------------------*/
.header {
  position: relative;
  color: #fff;
  height: 100vh;

  &_menu {
    padding-top: 40px;

    .header-branding {
      position: fixed;
      z-index: 100;

      &_site-title {
        a {
          display: block;
          width: clamp(15.625rem, 6.875rem + 25vw, 21.875rem);
          color: #fff;

          img {
            width: 100%;
          }
        }
      }
    }

    .menu-trigger {
      position: fixed;
      width: 60px;
      height: 60px;
      top: 0;
      right: 0;
      background-color: #fff;
      border: none;
      z-index: 110;
      cursor: pointer;

      &-bar {
        position: absolute;
        width: 30px;
        height: 4px;
        margin: auto;
        left: 0;
        right: 0;
        background-color: #000;

        &.top {
          top: 16px;
        }

        &.middle {
          top: 28px;
        }

        &.bottom {
          top: 40px;
        }
      }
    }

    .close-trigger {
      position: fixed;
      display: none;
      top: 0;
      right: 0;
      width: 60px;
      height: 60px;
      z-index: 110;
      cursor: pointer;

      &-bar {
        position: absolute;
        width: 30px;
        height: 4px;
        background-color: #fff;
        margin: auto;
        top: 30px;
        left: 0;
        right: 0;

        &.left {
          transform: rotate(45deg);
        }

        &.right {
          transform: rotate(135deg);
        }
      }
    }

    .global-nav {
      width: 60%;
      margin-left: auto;
      padding: 0 50px 0 400px;

      &_list {
        display: flex;
        justify-content: flex-end;
        gap: 2rem;

        li {
          position: relative;
          padding-top: 1rem;

          a {
            display: block;
            text-align: center;
            color: #fff;
            transition: .4s;
            text-shadow: 0 0 2px rgba(0, 0, 0, .3);
            font-size: 1.1rem;

            &:hover {
              color: variables.$color4;
              transition: .4s;
            }

            small {
              display: block;
              padding-top: 0.3rem;
              font-size: 0.8rem;
            }
          }
        }

        li:has(.global-nav_list-child) ul {
          position: absolute;
          left: 0;
          top: calc(100% + 1rem);
          z-index: 4;
          background: variables.$color3;
          visibility: hidden;
          opacity: 0;
          transition: all .3s;
        }

        li:has(.global-nav_list-child):hover>ul,
        li:has(.global-nav_list-child):active>ul {
          visibility: visible;
          opacity: 1;
        }

        li:has(.global-nav_list-child) ul li {
          padding: 0;
        }

        li:has(.global-nav_list-child) ul li a {
          display: block;
          padding: 1rem 2rem;
          color: #fff;
        }

        li:has(.global-nav_list-child) ul li:not(:last-child)>a {
          border-bottom: solid 1px rgba(255, 255, 255, 0.6);
        }

        li:has(.global-nav_list-child) ul li a:hover,
        li:has(.global-nav_list-child) ul li a:active {
          background: variables.$color4;
        }
      }
    }

    .mini-nav {
      position: fixed;
      display: grid;
      place-items: center;
      width: 0;
      height: 0;
      top: 0;
      right: 0;
      background-color: #000;
      opacity: 0;
      z-index: 90;

      &_list {

        .mini-nav_item {
          position: relative;
          padding: clamp(1rem, 0.02rem + 2.8vw, 1.7rem) 1rem;

          &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            translate: -50%;
            display: block;
            width: 100%;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.3);
          }

          a {
            display: flex;
            align-items: center;
            gap: clamp(1.3rem, 0.6rem + 2vw, 1.8rem);
            color: #fff;
            font-size: clamp(1.1rem, 0.54rem + 1.6vw, 1.5rem);
            transition: .4s;

            &:hover {
              color: variables.$color4;
              transition: .4s;
            }

            small {
              display: block;
              font-size: clamp(0.75rem, 0.4rem + 1vw, 1rem);
            }
          }

          &.current {
            a {
              color: #333;
            }
          }

          .mini-nav_item-child {

            position: relative;
            padding: clamp(1rem, 0.3rem + 2vw, 1.5rem) 0 0 0;

            a {
              display: flex;
              align-items: center;
              gap: clamp(1.2rem, 0.64rem + 1.6vw, 1.6rem);
              color: #fff;
              font-size: clamp(0.7rem, 2vw, 1.2rem);
              transition: .4s;

              &:hover {
                color: variables.$color4;
                transition: .4s;
              }

              &::before {
                content: "";
                width: 1em;
                height: 1px;
                background-color: currentColor;
                opacity: .3;
              }

              small {
                display: block;
                font-size: clamp(0.625rem, 0.45rem + 0.5vw, 0.75rem);
              }
            }
          }
        }
      }
    }
  }

  &_catchphrase {
    position: absolute;
    inset: 0;
    z-index: 30;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-inline: 1rem;
    text-shadow: 0 0 2px rgba(0, 0, 0, .3);

    h2 {
      word-break: keep-all;
      font-size: clamp(1.8rem, 0.12rem + 4.8vw, 3rem);
      line-height: clamp(3rem, 1.6rem + 4vw, 4rem);
    }

    &-desc {
      word-break: keep-all;
      font-size: clamp(1rem, 0.72rem + 0.8vw, 1.2rem);
    }
  }

  &_slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    overflow: hidden;

    .header_slider-img {
      position: relative;
      display: inline-block;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 100vh;
      margin: 0;
      width: 100%;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    &-img01 {
      background-image: url(img/top_slider01.jpg);
    }

    &-img02 {
      background-image: url(img/top_slider02.jpg);
    }

    &-img03 {
      background-image: url(img/top_slider03.jpg);
    }
  }

  &_lower-img {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.2);
    }

    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }

  &::before {
    border-top: 3vw solid transparent;
    border-bottom: 3vw solid transparent;
    border-left: 40vw solid rgba(variables.$decoColor, 0.5);
    bottom: -3vw;
  }

  &::after {
    border-top: 7vw solid transparent;
    border-right: 60vw solid rgba(variables.$decoColor, 0.3);
    border-bottom: 7vw solid transparent;
    bottom: -7vw;
  }
}

/*--------------------------------------------------------------
## main
--------------------------------------------------------------*/
:where(.lower) .contents {
  padding: clamp(3rem, 0.2rem + 8vw, 5rem) 0;

  &:first-child {
    padding-top: clamp(5rem, -2rem + 20vw, 10rem);
  }

  &:last-child {
    padding-bottom: clamp(6rem, -2.4rem + 24vw, 12rem);
  }
}

/*--------------------------------------------------------------
## toppage
--------------------------------------------------------------*/
.top {
  .contents {
    position: relative;
  }

  .contents_title {
    font-size: 4rem;
  }

  .contents_text {
    word-break: auto-phrase;
    text-wrap: balance;
  }

  .contents_title-main {
    font-size: clamp(2.3rem, 1.04rem + 3.6vw, 3.2rem);
  }

  .contents_title-sub {
    margin-top: 0;
    font-size: clamp(1rem, 0.72rem + 0.8vw, 1.2rem);
  }

  .contents01 {
    padding: 10rem 0;

    .contents_title {
      word-break: keep-all;
      text-align: center;
      font-size: clamp(1.2rem, 0.08rem + 3.2vw, 2rem);
      line-height: clamp(2.5rem, 0.4rem + 6vw, 4rem);
    }

    .contents_text {
      text-align: center;
    }
  }

  .contents02 {
    .contents_section {
      position: relative;
      margin-bottom: 30rem;

      &-img {
        max-height: 80vh;

        img {
          width: 100%;
          max-height: 80vh;
          object-fit: cover;
        }
      }

      &-text {
        position: absolute;
        width: 880px;
        min-height: 300px;
        top: 60vh;
        z-index: 10;

        a {
          display: block;
          padding: 6rem 8rem;
          color: #fff;
          min-height: 300px;

          .contents_title {
            margin-bottom: 3rem;
          }

          .more-btn {
            position: absolute;
            right: 9rem;
            bottom: 4rem;
            transition: .4s;

            &::before,
            &::after {
              background-color: #fff;
            }
          }

          &:hover .more-btn {
            translate: 3vw;
          }
        }
      }

      &:first-child {
        .contents_section-img {
          &::before {
            border-right: 20vw solid transparent;
            border-bottom: 4vw solid rgba(0, 0, 0, 0.2);
            bottom: 0;
            z-index: 10;
          }

          &::after {
            border-right: 80vw solid rgba(0, 0, 0, 0.2);
            border-bottom: 10vw solid transparent;
            bottom: -10vw;
          }
        }

        .contents_section-text {
          background-color: variables.$color1;
          right: 0;
        }
      }

      &:nth-child(2) {
        .contents_section-img {
          &::before {
            border-right: 60vw solid transparent;
            border-bottom: 6vw solid rgba(variables.$decoColor, 0.3);
            bottom: 0;
            z-index: 10;
          }

          &::after {
            border-bottom: 6vw solid transparent;
            border-top: 2vw solid transparent;
            border-right: 40vw solid rgba(variables.$decoColor, 0.5);
            bottom: -6vw;
          }
        }

        .contents_section-text {
          background-color: variables.$color1;
        }
      }
    }
  }

  .contents03 {
    padding: 8rem 0 0;
    background-color: rgba(0, 0, 0, 0.1);

    .container a {
      display: block;
      min-height: 6rem;
      margin-bottom: 8rem;

      .contents_title {
        text-align: left;
        color: #000;
      }

      .more-btn {
        position: absolute;
        color: #000;
        left: 0;
        transition: .4s;
        padding-left: 30rem;
      }

      &:hover .more-btn {
        translate: 3vw;
      }
    }

    .contents_item {

      .article {
        position: relative;
        width: 50%;
        height: 32vw;
        transition: .3s ease-in-out;

        a {
          display: block;
          height: 32vw;

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            left: 0;
            z-index: -1;
          }

          .article-text {
            display: none;
            color: #fff;
            padding: 5vw;

            &_title {
              font-size: 3rem;
              padding-top: 10vw;
              line-height: 3.5rem;
            }

            &_data {
              position: relative;
              margin: 1.2rem 0;

              &::before {
                position: absolute;
                content: '';
                width: 50px;
                height: 1px;
                background-color: #fff;
                bottom: -1rem;
                left: 0;
              }
            }
          }
        }

        &:hover {
          background-color: rgba(0, 59, 95, 0.5);
          transition: .3s ease-in-out;

          a {
            .article-text {
              display: block;
            }
          }
        }
      }
    }
  }

  .contents04 {
    padding: 8rem 0 25rem;
    position: relative;

    .contents_title {
      margin-bottom: 7rem;
      text-align: center;
    }

    .contents_item {
      margin-inline: auto;

      .article {
        display: grid;
        grid-template-columns: min(25%, 250px) 1fr;
        gap: clamp(1rem, 0.455rem + 2.73vw, 2.5rem);
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid #ddd;

        .article_img {
          aspect-ratio: 1.414 / 1;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

        }

        .article-text {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-template-rows: auto 1fr;
          gap: clamp(1rem, -0.4rem + 4vw, 2rem) clamp(1rem, -0.4rem + 4vw, 2rem);
          color: #333;

          &_data {
            line-height: 2;
            font-size: 0.9rem;
            margin-block: 0;
            padding-bottom: 0.5rem;
            color: #999;
          }

          &_categories {}

          &_title {
            grid-column: span 2;
          }

          &_title a {
            line-height: 1.8;
            font-size: clamp(1.1rem, 1.027rem + 0.36vw, 1.3rem);
            padding-bottom: 0.5rem;
            color: #222;
          }

          &_content {
            // grid-column-start: 3;
          }
        }
      }
    }

    .more-btn {
      position: absolute;
      right: 10vw;
      bottom: 18rem;
      transition: .4s;

      span {
        color: #000;
        display: block;
        width: 200px;
        height: 2rem;
        text-align: right;
      }

      &:hover {
        translate: 3vw;
        transition: .4s;
      }
    }

    &::before {
      border-right: 70vw solid transparent;
      border-bottom: 12vw solid rgba(variables.$decoColor, 0.6);
      bottom: 0;
    }

    &::after {
      border-left: 30vw solid transparent;
      border-bottom: 8vw solid rgba(variables.$decoColor, 0.4);
      bottom: 0;
    }
  }

  .contents05 {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 8rem 0;
    text-align: center;
    overflow: hidden;

    .contents_bk {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .contents_title {
      margin-bottom: 5rem;
    }

    p {
      margin-bottom: clamp(1rem, 0.273rem + 3.64vw, 3rem);
    }

    .btn1 {
      color: #fff;
      border-color: #fff;

      &::before {
        border-color: #fff;
      }
    }
  }

  .p-index-contents01 {
    padding-top: 5rem;

    .p-index-contents01__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: max(5%, 3.5rem);
    }

    .p-index-contents01__item {}

    .p-index-contents01__box {
      position: relative;
      margin-top: -15%;
      margin-left: auto;
      width: 90%;
      box-sizing: border-box;
      padding: max(8%, 1.5rem);
      background-color: variables.$color1;
      color: #fff;
    }

    .p-index-contents01__head {
      margin-bottom: 2rem;
      font-size: 1.8rem;
      // color: variables.$color1;
    }

    .p-index-contents01__desc {
      margin-bottom: 2.5rem;
    }

    .p-index-contents01__img {
      width: 90%;
      aspect-ratio: 1.414 / 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .p-index-contents01__item:nth-child(odd) {
      .p-index-contents01__box {}

      .p-index-contents01__img {}
    }

    .p-index-contents01__item:nth-child(even) {
      .p-index-contents01__box {}

      .p-index-contents01__img {
        margin-top: 10%;
      }
    }
  }
}

/*--------------------------------------------------------------
## lowerpage
--------------------------------------------------------------*/
/* 見出し */
.header_pageTitle {
  position: absolute;
  top: 42vh;

  h2 {
    text-shadow: 0 0 2px rgba(0, 0, 0, .3);
    font-size: 4rem;
    padding-left: 10vw;

    span {
      font-size: 2rem;
      display: block;
      margin-top: 0.5rem;
    }
  }
}

.lower .contents_title {
  font-size: 2rem;
}

/* 投稿内aタグ */
.post_content a {
  transition: .4s;

  &:hover {
    color: variables.$color1;
    transition: .4s;
  }
}

/*--------------------------------------------------------------
## about.php
--------------------------------------------------------------*/
.about {
  .contents01 {
    .contents_title {
      margin-bottom: clamp(1.5rem, 0.1rem + 4vw, 2.5rem);
      word-break: keep-all;
      font-size: clamp(1.3rem, 1.02rem + 0.8vw, 1.5rem);
      line-height: 2.8rem;
      text-align: center;
    }

    .contents_desc {
      word-break: keep-all;
      text-align: center;
    }
  }

  .contents02 {
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 12rem;
    padding-bottom: 20rem;

    .container {
      background-color: #fff;
      padding: 5rem 0;
    }

    h3 {
      text-align: center;
    }

    table {
      margin: 5rem auto 1rem;
    }

    &::before {
      border-right: 60vw solid transparent;
      border-bottom: 15vw solid rgba(variables.$decoColor, 0.4);
      bottom: 0;
    }

    &::after {
      border-left: 40vw solid transparent;
      border-bottom: 8vw solid rgba(variables.$decoColor, 0.2);
      bottom: 0;
    }
  }

  .img1 {
    background-image: url('img/about_bk.jpg');
  }

  .contents03 {
    &::before {
      border-right: 50vw solid transparent;
      border-top: 15vw solid rgba(variables.$decoColor, 0.2);
      top: 0;
    }

    &::after {
      border-left: 50vw solid transparent;
      border-top: 8vw solid rgba(variables.$decoColor, 0.4);
      top: 0;
    }

    h3 {
      text-align: center;
    }

    table {
      margin: auto;
      margin-top: 5rem;
    }
  }
}

/*--------------------------------------------------------------
## service.php
.--------------------------------------------------------------*/
.service {
  .contents01 {
    .contents_title {
      word-break: keep-all;
      font-size: clamp(1.1rem, 0.82rem + 0.8vw, 1.3rem);
      line-height: 2.8rem;
      text-align: center;
    }
  }

  .contents03 {
    background-color: rgba(0, 0, 0, 0.1);
    padding-bottom: 12rem !important;
    text-align: center;

    p {
      margin-bottom: 8rem;
      font-size: 1.2rem;
      line-height: 2rem;
    }

    &::before {
      border-right: 60vw solid transparent;
      border-bottom: 12vw solid rgba(variables.$decoColor, 0.3);
      bottom: 0;
    }

    &::after {
      border-left: 40vw solid transparent;
      border-bottom: 8vw solid rgba(variables.$decoColor, 0.5);
      bottom: 0;
    }
  }

}

/*--------------------------------------------------------------
## interior-work.php, waterproofing-work.php
--------------------------------------------------------------*/
:is(.interior-work, .waterproofing-work) {
  .contents01 {
    .contents_title {
      word-break: keep-all;
      font-size: clamp(1.1rem, 0.82rem + 0.8vw, 1.3rem);
      line-height: 2.8rem;
      text-align: center;
    }
  }
}

/*--------------------------------------------------------------
## recruit.php
--------------------------------------------------------------*/
.recruit {
  .contents01 {
    .contents_title {
      word-break: keep-all;
      font-size: 1.3rem;
      line-height: 2.8rem;
      text-align: center;
    }
  }
}

/*--------------------------------------------------------------
## requirements01.php
--------------------------------------------------------------*/
.requirements01 {

  .contents02 {
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 12rem;
    padding-bottom: 20rem;

    .container {
      background-color: #fff;
      padding: 5rem 0;
    }

    h3 {
      text-align: center;
    }

    table {
      margin: 5rem auto 1rem;
    }

    &::before {
      border-right: 60vw solid transparent;
      border-bottom: 15vw solid rgba(variables.$decoColor, 0.4);
      bottom: 0;
    }

    &::after {
      border-left: 40vw solid transparent;
      border-bottom: 8vw solid rgba(variables.$decoColor, 0.2);
      bottom: 0;
    }
  }
}

/*--------------------------------------------------------------
## archive(no sidebar)
--------------------------------------------------------------*/
.articles {
  .article {
    width: calc(50% - 2rem);
    margin-bottom: 5rem;

    &_img {
      position: relative;
      overflow: hidden;
      height: 312px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: .4s;
      }

      .category {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    &_text {
      color: #000;
      padding: 1.5rem .5rem 0;

      &-title {
        font-size: 1.2rem;
        margin-bottom: .8rem;
        line-height: 2.2rem;
      }

      &-date {
        position: relative;
        font-size: .8rem;
        color: variables.$color2;
        padding-left: 0.2rem;
        margin-bottom: 2rem;

        &::after {
          position: absolute;
          content: '';
          width: 2rem;
          height: 1px;
          background-color: #000;
          left: 0.2rem;
          bottom: -0.8rem;
        }
      }
    }

    &:nth-child(odd) {
      margin-right: 2rem;
    }

    &:nth-child(even) {
      margin-left: 2rem;
    }

    a {
      transition: .4s;

      &:hover {
        .article_img {
          opacity: .8;
          transition: .4s;

          img {
            transform: scale(1.1);
            transition: .4s;
          }
        }

        .article_text {
          opacity: .8;
          transition: .4s;
        }
      }
    }
  }
}

.archive .contents01 {
  padding-bottom: 15rem !important;

  &::before {
    border-right: 40vw solid transparent;
    border-bottom: 8vw solid rgba(variables.$decoColor, 0.3);
    bottom: 0;
  }

  &::after {
    border-left: 60vw solid transparent;
    border-bottom: 15vw solid rgba(variables.$decoColor, 0.5);
    bottom: 0;
    z-index: -1;
  }
}

/*--------------------------------------------------------------
## archive-news.php
--------------------------------------------------------------*/
.news .articles {
  .article {
    width: 100%;
    margin: 0;
    padding-bottom: 2rem;
  }

  hr {
    border-top: 1px solid #777;
    width: calc(100% - 2rem);
    margin: 1.2rem auto;
  }
}

/*--------------------------------------------------------------
## single.php(no sidebar)
--------------------------------------------------------------*/
.single {
  .contents01 {
    padding-bottom: 0;
  }

  .contents02 {
    padding-top: 0;
  }

  .post_content * {
    margin-bottom: 0.5rem;
  }
}

.post {
  margin-bottom: 3rem;

  &_title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 7rem;
  }

  &_img {
    width: 100%;
    margin-bottom: 3rem;
    object-fit: contain;
    height: auto;
  }

  &_data {
    width: calc(100% - 2rem);
    justify-content: space-between;
    margin: auto;
    margin-bottom: 4rem;

    &-date {
      position: relative;
      margin-block: 0;
      font-size: .9rem;

      &::before {
        position: absolute;
        content: '';
        width: 2rem;
        height: 1px;
        background-color: variables.$color2;
        bottom: -1rem;
      }
    }

    .category {
      a {
        color: #fff;
        display: block;
      }
    }
  }

  &_content {
    padding: 0 1rem;
    line-height: 1.7;
  }
}

/*--------------------------------------------------------------
## sidebar
--------------------------------------------------------------*/

.widget-container {
  width: calc(340px - 4rem);
  margin-left: 4rem;

  &_title {
    position: relative;
    font-size: 1.5rem;
    font-weight: bold;
    border-bottom: 2px solid #000;
    line-height: 3rem;
    padding-left: 0.5rem;

    span {
      font-size: 0.9rem;
      font-weight: normal;
      padding-left: 0.5rem;
    }

    &::before {
      position: absolute;
      content: '';
      border-bottom: 52px solid transparent;
      z-index: -1;
      left: 0;
    }
  }

  &:nth-child(odd) h2::before {
    border-left: 52px solid rgba(0, 0, 0, 0.2);
  }

  &:nth-child(even) h2::before {
    border-left: 52px solid rgba(variables.$decoColor, 0.5);
  }

  ul {
    padding: 0.5rem 1rem 7rem;

    li {
      line-height: 3.2rem;
      border-bottom: 1px solid #000;

      a {
        display: block;
        color: #000;
        transition: .3s;

        &:hover {
          color: variables.$color2;
          transition: .3s;
        }
      }
    }
  }
}

/*--------------------------------------------------------------
## archive(sidebar)
--------------------------------------------------------------*/
.archive.with-sidebar {
  .contents01 {
    padding-bottom: 0 !important;
  }

  .contents02 {
    padding-top: 0;
    padding-bottom: 15rem;

    .container {
      .articles {
        position: relative;
        width: calc(100% - 340px);

        .article {
          width: calc(50% - 2rem);

          &_img {
            height: 210.5px;
          }
        }

        .box_page-numbers {
          position: absolute;
          bottom: -3rem;
          width: 100%;
        }
      }
    }

    &::before {
      border-right: 40vw solid transparent;
      border-bottom: 8vw solid rgba(variables.$decoColor, 0.3);
      bottom: 0;
    }

    &::after {
      border-left: 60vw solid transparent;
      border-bottom: 15vw solid rgba(variables.$decoColor, 0.5);
      bottom: 0;
      z-index: -1;
    }
  }
}

/*--------------------------------------------------------------
## single.php(sidebar)
--------------------------------------------------------------*/
.single.with-sidebar {
  .contents02 {
    padding-bottom: 15rem;

    .container {
      .post {
        width: calc(100% - 340px - 2rem);
        padding-right: 2rem;

        &_img {
          height: 424px;
        }
      }
    }

    &::before {
      border-right: 40vw solid transparent;
      border-bottom: 8vw solid rgba(variables.$decoColor, 0.3);
      bottom: 0;
    }

    &::after {
      border-left: 60vw solid transparent;
      border-bottom: 15vw solid rgba(variables.$decoColor, 0.5);
      bottom: 0;
    }
  }
}

/*--------------------------------------------------------------
## page-contact
--------------------------------------------------------------*/

/* 電話でのお問い合わせ */

.p-contact-tel {
  margin-bottom: clamp(2rem, -3.6rem + 16vw, 6rem);

  p:not([class]),
  br:not([class]) {
    /* 自動整形対策 */
    display: contents;
  }

  .p-contact-tel__ttl {
    margin-bottom: 30px;
    text-align: center;
    font-size: clamp(1.5rem, 1.08rem + 1.2vw, 1.8rem);
  }

  .p-contact-tel__box {
    padding: 50px 20px;
    box-sizing: border-box;
    margin: clamp(1.875rem, -1.625rem + 10vw, 4.375rem) auto;
    background-color: rgba(variables.$color1, 0.1);
    text-align: center;
  }

  .p-contact-tel__message {
    margin-bottom: 1.2rem;
  }

  .p-contact-tel__number {
    margin: 0;
    font-size: 28px;

    a {
      text-decoration: none;
    }
  }

  .p-contact-tel__icon {
    width: 1em;
    margin-right: 0.3em;
    fill: currentColor;
  }

  .p-contact-tel__notes {
    margin-bottom: 0;
    font-size: 0.9rem;
  }
}

/* お問い合わせフォーム */

.p-contact-form {

  p:not([class]),
  br:not([class]) {
    /* 自動整形対策 */
    display: contents;
  }

  .required,
  .optional {
    flex-shrink: 0;
    display: inline-block;
    height: 1lh;
    line-height: 2.2em;
    margin-left: 8px;
    padding: 0 0.8em;
    box-sizing: content-box;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    font-weight: normal;
  }

  .required {
    background-color: #C50931;
  }

  .optional {
    background-color: #555;
  }

  & &__ttl {
    margin-bottom: 30px;
    text-align: center;
    font-size: clamp(1.5rem, 1.08rem + 1.2vw, 1.8rem);
  }

  & &__message {
    text-align: center;
  }

  & &__table {
    width: 100%;

    :is(th, td) {
      padding: 20px;
      box-sizing: border-box;
      background-color: transparent;
      border: none;
      vertical-align: top;
    }

    th {
      width: 280px;

      .th-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    input,
    select,
    textarea {
      padding: 0.5em;
      box-sizing: border-box;
      font-size: 1rem;
    }

    :is(input[type="text"], input[type="email"], input[type="tel"], textarea):is([size="40"], :not([size])) {
      width: 100%;
    }

    textarea {
      resize: vertical;
    }
  }

  & &__privacy__check {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 3rem;
    text-align: center;


    & &__txt {
      max-width: calc(100% - 30px);
      margin-bottom: 1em;
    }

    a {
      text-decoration: underline;
    }

    .wpcf7-acceptance label {
      input[type="checkbox"] {
        margin-right: 0.4rem;
      }
    }
  }

  & &__submit__btn {
    display: block;
    width: min(100%, 250px);
    max-width: 350px;
    margin: 0 auto;

    input[type="submit"] {
      width: 100%;
      padding: 0.8rem 2rem;
      background-color: #222;
      text-align: center;
      letter-spacing: 0.2em;
      color: #fff;
      font-size: 1rem;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      transition: .5s;
      cursor: pointer;

      &:hover {
        background-color: #333;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      }
    }
  }

  .privacy__embed {
    /* プライバシーポリシー埋め込み */
    width: 100%;
    height: 400px;
    margin: 0 auto;
    padding: 2rem;
    box-sizing: border-box;
    overflow-y: scroll;
    background-color: #eee;
    border: 1px solid #cfcfcf;
    color: #333;

    h3 {
      text-align: center;
    }
  }
}

/*--------------------------------------------------------------
## footer.php
--------------------------------------------------------------*/
.footer__cta {
  background-color: #d8d8d8;
  padding: 7rem 0 12rem;
  text-align: center;

  p {
    margin-bottom: 4rem;
    word-break: keep-all;
    font-size: 1.2rem;
    line-height: 2.5rem;
  }

  &::before {
    border-right: 60vw solid transparent;
    border-bottom: 12vw solid rgba(variables.$decoColor, 0.3);
    bottom: 0;
  }

  &::after {
    border-left: 40vw solid transparent;
    border-bottom: 8vw solid rgba(variables.$decoColor, 0.5);
    bottom: 0;
  }
}

.footer {
  position: relative;
  background-color: #183804;
  color: #fff;
  padding: 5rem 0;
  text-align: left;

  .container {
    justify-content: space-between;
  }

  .footer-box {
    width: 50%;
    line-height: 2rem;

    a {
      color: #fff;
      transition: .4s;
      text-decoration: underline;
    }

    h2 {
      margin-bottom: 200px;

      a {
        display: block;
        width: clamp(15.625rem, 6.875rem + 25vw, 21.875rem);
        color: #fff;
      }
    }

    &_text {
      &-left {
        width: calc(50% - 2rem);
        padding-right: 2rem;
      }

      &-right {
        width: 50%;

        .sns-nav {
          margin: 1rem 0;

          &_list {
            li {
              display: inline-block;
              margin-right: 0.8rem;
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }

  .copyright {
    position: absolute;
    bottom: 5rem;
    font-size: .8rem;
  }
}

/*####################################
	アニメーション
####################################*/

/*----------------------
  ベース部分
----------------------*/
.scrollTrigger,
.scrollTriggerChild {
  opacity: 0;
}

.scrollIn {
  animation-name: scrollIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes scrollIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    translate: 0;
  }
}

/*----------------------
  上下左右からフェードイン
----------------------*/
.fromTop {
  translate: 0 -2vh;
}

.fromBottom {
  translate: 0 2vh;
}

.fromRight {
  translate: 2vw;
}

.fromLeft {
  translate: -2vw;
}

/*----------------------
  ズーム
----------------------*/
.zoomIn {
  scale: 0.95;
}

.zoomIn.scrollIn {
  animation-name: zoom-scrollIn;
  animation-duration: 0.5s;
}

@keyframes zoom-scrollIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    scale: 1;
  }
}

/*----------------------
  横幅を切り取って出現させる
----------------------*/

.widthClippedLeft {
  clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  opacity: 0;
  transition: opacity 1s, clip-path 1s;
}

.widthClippedLeft.scrollIn {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  opacity: 1;
}

.widthClippedRight {
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
  opacity: 0;
  transition: opacity 1s, clip-path 1s;
}

.widthClippedRight.scrollIn {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  opacity: 1;
}

/*----------------------
  ディレイ
----------------------*/
.delay1 {
  animation-delay: 0.1s;
}

.delay2 {
  animation-delay: 0.2s;
}

.delay3 {
  animation-delay: 0.3s;
}

.delay4 {
  animation-delay: 0.4s;
}

.delay5 {
  animation-delay: 0.5s;
}

.delay6 {
  animation-delay: 0.6s;
}

.delay7 {
  animation-delay: 0.7s;
}